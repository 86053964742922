<template>
<div class="flex flex-grow">
  <div class="flex w-full border-black border-b-2"  
  v-bind:class="{'bg-gray-300 font-bold': isSunday, 'bg-maroon bg-opacity-25 font-bold': isToday}"
  v-show="this.show">
    <div class="w-1/8 pl-1 pr-2 py-1">{{ day_str }}.&nbsp;{{ day_name }} </div>
    <div class="w-full py-1">
      <ul>
        <li class="w-auto pr-2 text-left" v-for="event in this.event_list" :key=event.date>
          <span  v-if="event.ho < 1">
            {{ event.event_text }}
          </span>
          <span class="font-bold"  v-if="event.ho >= 1">
            {{ event.event_text }}
          </span>
          <a class="icsLink" :href="'data:text/plain;charset=utf-8,' + event.ics_str" :download="event.date.getFullYear() + '_' + event.name + '.ics'"> (ics)</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex w-full items-center border-black border-b-2"  v-if="this.duration > 1">
    <ul class="w-1/8">
      <template v-for="n in this.duration" :key=n>
        <li class="py-2 pl-1 pr-2 text-left bg-gray-300 font-bold" v-if="days_names[n-1]=='So'">{{ day_strs[n-1] }}.&nbsp;{{ days_names[n-1]}}
        </li>
        <li class="py-2 pl-1 pr-2 text-left" v-else>{{ day_strs[n-1] }}.&nbsp;{{ days_names[n-1]}}</li>
       </template>
    </ul>
    <div class="flex items-center h-full w-full pl-1 pr-2 py-1 bg-gray-100 justify-center">
      <span>{{ this.event_name }}
        <a class="icsLink" :href="'data:text/plain;charset=utf-8,' + this.ics_str" :download="this.date.getFullYear() + '_' + this.event_name + '.ics'"> (ics)</a>
      </span>
    </div>
  </div>
</div>
</template>

<script>
const zeroPad = (num, places) => String(num).padStart(places, '0')
var day_names = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
export default {
  name: "ProgramDay",
  props: ["date"],
  data() {
    var show = true;
    var event_list = [];
    var duration = 1;
    var day_strs =[];
    var days_names = [];
    var event_name = "";
    var ics_str = "";
    if (this.date.getTime() in this.$events){
      event_list.push(...this.$events[this.date.getTime()]);
    }
    for (let i = 0; i < event_list.length;i++){
      duration = Math.max(duration,event_list[i].duration);
      //format based on time information provided (or not)
      if (event_list[i].precision == "dead"){
        show = false;
      }
      else {
        if (event_list[i].date.getHours() == 0 && event_list[i].date.getMinutes() == 0){
          event_list[i].event_text = event_list[i].name;
        }
        else {
          switch(event_list[i].date.getMinutes()){
            case 0: event_list[i].precision = " st"; break;
            case 15: event_list[i].precision = " ct"; break;
            case 30: event_list[i].precision = " mt"; break;
            default:
              event_list[i].precision = ":" + zeroPad(event_list[i].date.getMinutes(),2); break;
          }
          event_list[i].event_text = zeroPad(event_list[i].date.getHours(),2) + event_list[i].precision + ' ' + event_list[i].name;
        }
      }
    }
    if (duration > 1){
      show = false;
      event_name = event_list[0].name;
      ics_str = event_list[0].ics_str;
      for (let d = 0; d < duration; d++){
        day_strs.push(zeroPad(this.date.getDate()+d,2));
        days_names.push(day_names[(this.date.getDay() + d)%7]);
      }
    } 
    var isToday = false;
    if ((new Date()).toDateString()==this.date.toDateString()) {
      isToday = true;
    }

    return {day_str:zeroPad(this.date.getDate(),2),
            isSunday:(0==this.date.getDay()),
            isToday:(isToday),
            day_name: day_names[this.date.getDay()],
            event_list:event_list,
            show:show,
            duration:duration,
            day_strs:day_strs,
            days_names:days_names,
            event_name:event_name,
            ics_str:ics_str
          };
  },
  computed:{
  }
};
</script>

<style>
a.icsLink {
  font-size: x-small;
}
</style>