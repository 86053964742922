<template>
  <div class="sempro">
 <Program></Program>

</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import Program from '@/components/Program.vue'

export default {
  name: 'ViewSempro',
  components: {
    Program
  },
  setup () {
    useMeta({ 
      title: 'Semesterprogramm',
      description: 'Semesterprogramm der Burschenschaft Allemannia' 
    })
  }
}
</script>


<style>
</style>
