import { createRouter, createWebHashHistory } from 'vue-router'
import '@/assets/tailwind.css'
import ViewHome from '@/views/ViewHome.vue'
import ViewCommunity from '@/views/ViewCommunity.vue'
import ViewHouse from '@/views/ViewHouse.vue'
import ViewSempro from '@/views/ViewSempro.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ViewHome
  },
  {
    path: '/Gemeinschaft',
    name: 'Community',
    component: ViewCommunity
  },
  {
    path: '/Haus',
    name: 'House',
    component: ViewHouse
  },
  {
    path: '/Sempro',
    name: 'Sempro',
    component: ViewSempro
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewAbout.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
