<template>
  <div class="home">
    <div class="community flex justify-center">
      <div class="md:w-2/3 max-w-screen-xl px-4 pt-8">
        <h1 class="text-3xl text-maroon text-center pb-4">
          Willkommen bei der Burschenschaft Allemannia Heidelberg
        </h1>
        <img
          class="md:float-right md:w-1/2 md:px-2 md:shadow md:ml-8 md:mb-4 w-full pt-4 pb-4 md:pb-0 max-w-lg"
          loading="eager"
          src="@/assets/img/website.jpg"
          srcset="
            @/assets/img/website.jpg     1000w,
            @/assets/img/website_800.jpg 1200w,
            @/assets/img/website_600.jpg  600w
          "
          sizes=" (max-width: 780px) 80vw, 1024px"
          alt="Einer unserer zahlreichen Gemeinschaftsräume"
        />
        <div class="text-left">
          <div class="max-w-lg">
            Allemannia ist die älteste Burschenschaft in Heidelberg und Mitglied
            des Süddeutschen Kartells. Auf dem Fundament eines Lebensbundes
            finden sich bei uns Menschen zusammen, die ihren Horizont erweitern
            wollen – während ihrer Gemeinschaft in der Studienzeit, und auch
            über das Studium hinaus. Wir bieten hier einen Überblick über uns
            und unsere Aktivitäten.
          </div>
          <div class="text-left pt-2">
            <h1><router-link to="/Gemeinschaft">Gemeinschaft</router-link></h1>
            <div>
              Interessiert an unserer Gemeinschaft? Schau mal vorbei und sprich
              uns an.
            </div>
          </div>
          <div class="text-left pt-2">
            <h1><router-link to="/Sempro">Semesterprogramm</router-link></h1>
            <div>Hier findest Du unsere aktuellen Veranstaltungen.</div>
          </div>
          <div class="text-left pt-2">
            <h1><router-link to="/Sempro">Haus</router-link></h1>
            <div>
              Unser Haus bietet gemütliche und preisgünstige Zimmer in der
              Altstadt von Heidelberg. Frag einfach mal nach.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="community flex justify-center">
      <div class="md:w-2/3 max-w-screen-xl px-4 pt-8">
        <h1 class="text-3xl text-maroon text-center pb-4">
          Süddeutsches Kartell
        </h1>
        <img
          class="md:float-left sm:w-2/3 md:w-1/3 lg:w-1/4 md:px-2 md:shadow md:mr-8 md:mb-4 w-full pt-4 pb-4 md:pb-0"
          loading="lazy"
          src="@/assets/img/sk/SK.jpg"
          srcset="
            @/assets/img/sk/SK.jpg     600w,
            @/assets/img/sk/SK_400.jpg 400w,
            @/assets/img/sk/SK_300.jpg 300w
          "
          alt="Süddeutsches Kartell"
        />
        <div class="text-left">
          <div
            class="grid_8 wow fadeInRight animated"
            data-wow-delay="0.4s"
            style="
              visibility: visible;
              animation-delay: 0.4s;
              animation-name: fadeInRight;
            "
          >
            <div
              id="c1521"
              class="frame frame-default frame-type-text frame-layout-0"
            >
              <p>
                <em
                  >Im Jahr 1861 gegründet, stellt das SK einen Zusammenschluss
                  mehrerer gleichgesinnter Burschenschaften dar. Ihm liegt der
                  Grundsatz: </em
                ><br /><br />
                „Ein Bund an sechs Hochschulen“ zugrunde. Anstatt nur in einer
                Verbindung aktiv zu sein, ist man gleichzeitig in sechs
                Verbindungen dabei. Man ist dort zwar nicht richtig mit eigener
                Bandaufnahme Mitglied, aber man genießt einen bundesbrüderlichen
                Status. Durch gegenseitige Besuche und Aktionen, wie Segeln in
                Kiel, Stocherkahn fahren in Tübingen, Wandern in Göttingen,
                Festball in Heidelberg oder Pauktag in Erlangen, lassen sich
                außerordentliche Freundschaften über die eigene
                Universitätsstadt hinaus knüpfen.
              </p>
            </div>
          </div>
        </div>

        <div
          class="w-full grid grid-flow-row my-4 mt-8 gap-8 gap-sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-black"
        >
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <p>
              <img
                loading="lazy"
                decoding="async"
                class="skHouse"
                src="@/assets/img/sk/Haus_TJ.png"
                srcset="
                  @/assets/img/sk/Haus_TJ.png     800w,
                  @/assets/img/sk/Haus_TJ_500.png 500w,
                  @/assets/img/sk/Haus_TJ_300.png 300w
                "
                sizes="(max-width: 328px) 80vw, (min-width: 320px max-width: 1000px) 60vw, 1024px"
                alt="Haus Teutonia Jena"
              />
            </p>
            <p class="text-center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Teutonia-Jena.png"
                alt="Teutonia-Jena"
              />
            </p>
            <p class="font-bold">Burschenschaft Teutonia Jena</p>
            <p class="italic">gegründet 1815</p>
            <p>
              <a
                class="textlink"
                href="https://www.teutonia-jena.de/"
                target="_blank"
                rel="noopener"
                >www.teutonia-jena.de</a
              ><br />
            </p>
          </div>
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <p>
              <img
                loading="lazy"
                decoding="async"
                src="@/assets/img/sk/Haus_GE.jpg"
                alt="Haus Germania Erlangen"
                class="skHouse"
                srcset="@/assets/img/sk/Haus_GE.jpg 500w"
                sizes="(max-width: 328px) 80vw, (min-width: 320px max-width: 1000px) 60vw, 1024px"
              />
            </p>
            <p style="text-align: center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Germania-Erlangen.png"
                alt="Germania-Erlangen"
              />
            </p>
            <p class="font-bold">Burschenschaft Germania Erlangen</p>
            <p class="italic">gegründet 1827</p>
            <p>
              <a
                class="textlink"
                href="https://germania-1827.de/"
                target="_blank"
                rel="noopener"
                >www.germania-1827.de</a
              >
            </p>
          </div>
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <p>
              <img
                loading="lazy"
                decoding="async"
                class="skHouse"
                src="@/assets/img/sk/Haus_GT.jpg"
                alt="Haus Germania Tübingen"
                sizes="(max-width: 328px) 80vw, (min-width: 320px max-width: 1000px) 60vw, 1024px"
              />
            </p>
            <p style="text-align: center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Germania-Tuebingen.png"
                alt="Germania-Tuebingen"
              />
            </p>
            <p class="font-bold">Burschenschaft Germania Tübingen</p>
            <p class="italic">gegründet 1816</p>
            <p>
              <a
                class="textlink"
                href="http://www.bixier.de/"
                target="_blank"
                rel="noopener"
                >www.bixier.de</a
              ><br />
            </p>
            <p></p>
          </div>
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <p>
              <img
                loading="lazy"
                decoding="async"
                class="skHouse"
                src="@/assets/img/sk/Haus_GK.jpg"
                alt="Haus Germania Königsberg"
                width="100%"
                srcset="@/assets/img/sk/Haus_GK.jpg 328w"
                sizes="(max-width: 328px) 80vw, (min-width: 320px max-width: 1000px) 60vw, 1024px"
              />
            </p>
            <p style="text-align: center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Gothia-Koenigsberg.png"
                alt="Gothia-Koenigsberg"
              />
            </p>
            <p class="font-bold">
              Königsberger Burschenschaft<br />
              Gothia zu Göttingen
            </p>
            <p class="italic">gegründet 1854</p>
            <p>
              <a
                class="textlink"
                href="http://www.gothia-koenigsberg.de/"
                target="_blank"
                rel="noopener"
                >www.gothia-koenigsberg.de</a
              >
            </p>
            <p></p>
          </div>
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <img
              loading="lazy"
              decoding="async"
              src="@/assets/img/sk/Haus_TK.jpg"
              alt="Haus Teutonia Kiel"
              class="skHouse"
              srcset="
                @/assets/img/sk/Haus_TK.jpg     1024w,
                @/assets/img/sk/Haus_TK_768.jpg  768w,
                @/assets/img/sk/Haus_TK_300.jpg  320w
              "
              sizes="(max-width: 328px) 80vw, (min-width: 320px max-width: 1000px) 60vw, 1024px"
            />
            <p></p>
            <p style="text-align: center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Teutonia-Kiel.png"
                alt="Teutonia-Kiel"
              />
            </p>
            <p class="font-bold">Burschenschaft Teutonia Kiel</p>
            <p class="italic">gegründet 1817</p>
            <p>
              <a
                class="textlink"
                href="https://www.teuten.de/"
                target="_blank"
                rel="noopener"
                >www.teuten.de</a
              >
            </p>
            <p></p>
          </div>
          <div
            class="pb-4 rounded shadow-lg bg-white duration-300 hover:-translate-y-1"
          >
            <img
              loading="lazy"
              decoding="async"
              class="skHouse"
              src="@/assets/img/sk/Haus_AH.jpg"
              alt="Haus Allemannia Heidelberg"
              srcset="@/assets/img/sk/Haus_AH.jpg 479w"
              sizes="(max-width: 479px) 100vw, 479px"
            />
            <p></p>
            <p style="text-align: center">
              <img
                loading="lazy"
                decoding="async"
                class="skBanner"
                src="@/assets/img/sk/Allemannia-Heidelberg.png"
                alt="Allemannia-Heidelberg"
              />
            </p>
            <p class="font-bold">Burschenschaft Allemannia zu Heidelberg</p>
            <p class="italic">gegründet 1856</p>
            <p>
              <a
                class="textlink"
                href="http://www.allemannia.de/"
                target="_blank"
                rel="noopener"
                >www.allemannia.de</a
              >
            </p>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  name: "ViewHome",
  components: {
    // IconBase
  },
  setup() {
    useMeta({
      title: false,
      description:
        "Burschenschaft Allemannia zu Heidelberg im Süddeutschen Kartell",
    });
  },
};
</script>

<style>
img.skBanner {
  @apply w-24 inline-block pb-4 pt-4;
  /*aligncenter*/
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.skHouse {
  @apply rounded-t w-full;
  /*alignnone*/
  clear: both;
  display: block;
}

</style>
