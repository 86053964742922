<template>
  <div class="community flex justify-center">
    <div class="lg:w-2/3 px-4 max-w-screen-xl py-8">
      <div class="overflow-auto">
        <h1 class="font-voll text-3xl text-maroon text-center pb-4">
          Lebenslang erfolgreiche Gemeinschaft
        </h1>
        <img
          class="lg:float-right lg:w-1/2 lg:mx-8 lg:px-2 lg:py-2 lg:shadow w-full pb-2"
          loading="eager"
          src="@/assets/img/community.jpg"
          srcset="
            @/assets/img/community.jpg,
            @/assets/img/community_1400.jpg 1400w,
            @/assets/img/community_800.jpg   800w
          "
          sizes=" (max-width: 967px) 100vw, (max-width: 1300px) 390px, 600px"
          alt="Einer unserer zahlreichen Gemeinschaftsräume"
        />
        <div class="text-left">
          Ab deinem Ersten Tag bei der Allemannia bist du Teil unserer
          erfolgreichen Gemeinschaft. Junge Studenten, die sich gegenseitig
          motivieren und von den Erfahrungen unserer älteren Mitglieder
          profitieren. Egal für welchen Studiengang du dich entschieden hast,
          egal wo du herkommst, in unserer Gemeinschaft vereinen sich die
          verschiedensten Menschen unter einer gemeinsamen Idee - Allemannia.
          Unsere Diversität und unser Mindset bilden die Grundlage für
          akademische Exzellenz. Was wir für dich tun:
          <ul class="list-disc px-8">
            <li>Lerngruppen zu jedem Studiengang</li>
            <li>Nachhilfe falls du sie brauchst</li>
            <li>
              Orientierungshilfen vom ersten Tag in Heidelberg bis zur
              Berufsfindung
            </li>
          </ul>
        </div>
      </div>
      <div class="block lg:pt-4">
        <h1 class="font-voll text-3xl text-maroon text-center py-4">
          Persönliche Entwicklung
        </h1>
        <img
          class="lg:float-left lg:w-1/2 lg:mr-8 lg:px-2 lg:py-2 lg:shadow w-full pb-2"
          loading="lazy"
          src="@/assets/img/community.jpg"
          srcset="
            @/assets/img/community.jpg,
            @/assets/img/community_1400.jpg 1400w,
            @/assets/img/community_800.jpg   800w
          "
          sizes=" (max-width: 967px) 100vw, (max-width: 1300px) 390px, 600px"
          alt="Einer unserer zahlreichen Gemeinschaftsräume"
        />

        <div class="text-left">
          Wir wollen, dass jeder Mensch sich bei uns zu seinem besten Selbst
          entwickelt. Deshalb fördern wir den Dialog zwischen Studium, Forschung
          und Arbeitswelt genau so wie gesellschaftliches und kulturelles
          Engagement. Durch enge Zusammenarbeit und Vorträge, aber auch durch
          Ermutigung des einzelnen wollen wir zur Entwicklung deines Charakters
          beitragen.
        </div>
      </div>
    </div>
  </div>
  <div class="hidden lg:block relative h-screen/2 pt-2">
    <div
      style="left: 50%; top: 40%"
      class="absolute inset-y-1/2 transform -translate-x-1/2 -translate-y-1/2 font-voll text-wheat text-6xl z-10 align-top"
    >
      Allemannia
    </div>
    <img
      style="filter: sepia(40%) brightness(55%) saturate(2)"
      class="object-cover object-center w-full h-full"
      loading="lazy"
      src="@/assets/img/holzwappen.jpg"
      srcset="
        @/assets/img/holzwappen.jpg,
        @/assets/img/holzwappen_1400.jpg 1400w,
        @/assets/img/holzwappen_800.jpg   800w
      "
      sizes="100vw"
    />
    <div
      style="left: 50%; top: 65%"
      class="absolute inset-y-1/2 transform -translate-x-1/2 -translate-y-1/2 font-voll text-wheat text-2xl z-10 align-top"
    >
      Im Herzen Heidelbergs
    </div>
  </div>
  <div class="community flex justify-center">
    <div class="lg:w-2/3 px-4 max-w-screen-xl py-8 min-w-0 min-h-0">
      <div class="overflow-auto">
        <h1 class="font-voll text-3xl text-maroon text-center lg:py-4 pb-4">
          Mehr als nur Studieren
        </h1>

        <img
          class="lg:float-left lg:w-1/2 lg:mr-8  lg:px-2 lg:py-2 lg:shadow w-full pb-2"
          loading="lazy"
          src="@/assets/img/weber.jpg"
          srcset="
            @/assets/img/weber.jpg,
            @/assets/img/weber_1400.jpg 1400w,
            @/assets/img/weber_800.jpg   800w
          "
          sizes=" (max-width: 967px) 100vw, (max-width: 1300px) 390px, 600px"
          alt="Einer unserer zahlreichen Gemeinschaftsräume"
        />
        <div class="text-left">
          Als Teil der Allemannia lernst du mehr als nur auf Klausuren. Du
          selbst trägst mit zu der aktiven Gestaltung unseres Bundeslebens bei.
          Durch die Verantwortung, die Du trägst, lernst du das, was später
          wirklich zählt. Von Leadershipskills bis Zeitmanagement, Teamwork bis
          Verhandlungsgeschick, Rhetorik bis Organisationsfähigkeiten,
          trainierst Du dir durch Deine Zeit bei uns ein wichtiges Fundament an
          Softskills an, das du im Beruf brauchen wirst. Damit inspirierst Du
          Deine Kommilitonen und Freunde.
        </div>
      </div>
      <div class="overflow-auto block pt-4">
        <h1 class="font-voll text-3xl text-maroon text-center py-4">
          Tradition, die verpflichtet
        </h1>
        <img
          class="lg:float-right lg:w-1/2 lg:mx-8 lg:px-2 lg:py-2 pb-2 lg:shadow w-full"
          loading="lazy"
          src="@/assets/img/tradition.jpg"
          srcset="
            @/assets/img/tradition.jpg,
            @/assets/img/tradition_1400.jpg 1400w,
            @/assets/img/tradition_800.jpg   800w
          "
          sizes=" (max-width: 967px) 100vw, (max-width: 1300px) 390px, 600px"
          alt="Einer unserer zahlreichen Gemeinschaftsräume"
        />
        <div class="block text-left">
          Seit über 150 Jahren sind wir in Heidelberg verankert. Aus unseren
          Reihen sind große Staatsmänner und erfolgreiche Wissenschaftler
          hervorgegangen. Der Tradition, die unsere Gründerväter schufen, die
          dem Erfolg der Allemannia innewohnt, sind wir heute noch durch unsere
          freien und demokratischen Grundsätze verpflichtet. Im Bestreben
          mündige Mitglieder der Gesellschaft zu werden geben wir uns unser
          eigenes Regelwerk, in dem Intoleranz oder Extremismus keinen Platz
          hat.
        </div>
      </div>
          <h2 class="font-voll text-xl text-maroon pt-8 pb-4"> Berühmte Allemannen </h2>
          <AccordionComponent />
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import AccordionComponent from "@/components/Accordion";

export default {
  name: "ViewCommunity",
  components: {
    AccordionComponent
    // HeaderNav
  },
  setup() {
    useMeta({
      title: "Gemeinschaft",
      description: "Gemeinschaft der Burschenschaft Allemannia",
    });
  },
};
</script>

<style></style>
