<template>
  <on-click-outside :do="handleClickOutside">
    <div class="relative block shadow-xl w-16 mx-auto">
      <button @click="open = !open" type="button" class="block text-app-menu-text focus:outline-none">
        <svg class="h-10 w-10 fill-current" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
        </svg>
      </button>
      <div v-show="open" class="absolute shadow-lg rounded-lg top-auto bg-app-menu-hover white mt-2 px-1 py-1 right-0 rounded-b">
          <a @mouseup="handleClickOutside" href="#" class="smallMenuItem"><router-link to="/">Start</router-link></a>
          <a @mouseup="handleClickOutside" href="#" class="smallMenuItem"><router-link to="/Gemeinschaft">Gemeinschaft</router-link></a>
          <a @mouseup="handleClickOutside" href="#" class="smallMenuItem"><router-link to="/Sempro">Semesterprogramm</router-link></a>
          <a @mouseup="handleClickOutside" href="#" class="smallMenuItem"><router-link to="/Haus">Haus</router-link></a>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from "./OnClickOutside.vue";

export default {
  name: "DropdownDemo",
  components: {
    OnClickOutside
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    handleClickOutside() {
      if (this.open) {
        this.open = false;
      }
    }
  }
};
</script>

<style>

a.smallMenuItem {
  /*reversed txt color in small menu*/
  @apply block px-6 py-4 text-2xl font-serif rounded text-app-menu-text-hover hover:bg-app-menu hover:text-app-menu-text;
}
</style>