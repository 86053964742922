<template>
<header class="select-none sticky top-0 shadow-lg w-full px-4 bg-app-menu z-90 border-b-4 border-yellow-100">
    <!-- small mobile stuff -->
    <div class="flex lg:hidden items-center w-full h-16">
        <div class="w-1/3"></div>
        <div class="w-1/3 flex justify-center">
            <router-link to="/">
                <!--<img class="w-1/3 h-16 px-2 py-2 zirkel transition-all" src="../assets/img/Zirkel.svg"/> -->
                <div class="h-14 w-14">
                    <img class="h-14 px-1 py-1 hoverZirkel" src="../assets/img/Zirkel.svg" alt="Zirkel"/>
                </div>
            </router-link>
        </div>
        <div class="w-1/3 flex justify-end">
            <div>
                <dropdown></dropdown>
            </div>
        </div>
    </div>
    <div class="hidden lg:flex items-center w-full justify-center" >
        <div class="hidden lg:flex w-4/5 max-w-screen-xl justify-between items-center">           
            <HeaderButton><router-link to="/">Start</router-link></HeaderButton>
            <HeaderButton><router-link to="/Gemeinschaft">Gemeinschaft</router-link></HeaderButton>
            <router-link to="/">    
                <div class="h-14 w-14">
                    <img class="h-14 px-1 py-1 hoverZirkel" src="../assets/img/Zirkel.svg" alt="Zirkel"/>
                </div>
            </router-link>
            <HeaderButton><router-link to="/Sempro">Semesterprogramm</router-link></HeaderButton>  
            <HeaderButton><router-link to="/Haus">Haus</router-link></HeaderButton>
        </div>
    </div>       
  </header>
</template>

<script>
import Dropdown from "./Dropdown";
import HeaderButton from "./HeaderButton";
export default {
  name: "HeaderNav",
components: {Dropdown,HeaderButton}
};
</script>
<style>/*height: 4.25rem;*/
img.hoverZirkel:hover {
    @apply pt-0 px-0 h-16;
    
}
</style>