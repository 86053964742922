import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import router from './router'
import program from "@/assets/data/program.csv"
import famous from '@/assets/data/famous.json' 


//zero padding for data fixes
const My_zp = function(s){ return ("0"+s).slice(-2); }

//iso date for ical formats
const My_isofix = function(d){
    var offset = ("0"+((new Date()).getTimezoneOffset()/60)).slice(-2);

    if(typeof d=='string'){
        return d.replace(/-/g, '')+'T'+offset+'0000Z';
    }else{
        return d.getUTCFullYear()+My_zp(d.getUTCMonth()+1)+My_zp(d.getUTCDate())+'T'+My_zp(d.getUTCHours())+My_zp(d.getUTCMinutes())+"00Z";
    }
}
		

// usage example:
// var a = ['a', 1, 'a', 2, '1'];
// var unique = a.filter(onlyUnique);
const month_days = [31,28,31,30,31,30,31,31,30,31,30,31];

const parseProgram =function(program){
    var winter_term = false;
    var events = {};
    var years = [];

    for (let line of program){
        let key_date = new Date(line.date);
        if (key_date !== undefined){
            // events[months.indexOf(key_date.getMonth())].push(line);
            if (years.indexOf(key_date.getFullYear()) < 0){
                years.push(key_date.getFullYear());
            }
        }

        let date = new Date(line.date);
        key_date.setHours(0);
        key_date.setMinutes(0);
        var event = {precision:undefined,date:date,ho:line.ho,name:line.name,duration:line.duration,event_text:undefined,ics_str:undefined}
        
        var now = new Date();
        var endDate = new Date();
        endDate.setTime(event.date.getTime() + 2 * 60 * 60 * 1000);
        var ics_lines = [
            "BEGIN:VCALENDAR",
            "VERSION:2.0",
            "PRODID:-//Allemannia e.V.//iCalAdUnit//DE",
            "METHOD:REQUEST",
            "BEGIN:VEVENT",
            "TZID:Europe/Berlin",
            "X-LIC-LOCATION:Europe/Berlin",
            "UID:event-"+event.date.getTime()+"@allemannia.de",
            //"DTSTAMP:"+this._isofix(now),
            "DTSTART:"+My_isofix(event.date),
            "DTEND:"+My_isofix(endDate),
            "DURATION:"+"P"+(event.date.getHours() == 0 ? event.duration+"D" : "T2H"),
            "DESCRIPTION:"+event.name,
            "SUMMARY:"+event.name,
            "LAST-MODIFIED:"+My_isofix(now),
            "SEQUENCE:0",
            "END:VEVENT",
            "END:VCALENDAR"
        ];
        event.ics_str = ics_lines.join('\r\n');//btoa();
        if (!(key_date.getTime() in events)){
            events[key_date.getTime()] = [event];
        } else {
            events[key_date.getTime()].push(event);
        }
    }
    for (let date_string in events){
        if (events[date_string][0].duration>1){
            let dur = events[date_string][0].duration;
            let base = events[date_string][0].date;
            for (let d=1;d < dur;d++){
                let ndate = new Date(base.getFullYear(),base.getMonth(),base.getDate()+d);
                let nevent = {precision:"dead",date:ndate,ho:undefined,name:undefined,duration:undefined};
                events[ndate.getTime()] = [nevent];
            }
        }
    }

    years.sort();
    if (years.length>1){
        winter_term = true;
    }

    return {events:events, winter:winter_term, start_year:years[0]}
}

const app = createApp(App)
app.use(router)
.use(createMetaManager())
.mount('#app')

var parsed_program = parseProgram(program);
app.config.globalProperties.$events = parsed_program.events;
app.config.globalProperties.$winter = parsed_program.winter;
app.config.globalProperties.$start_year = parsed_program.start_year;
app.config.globalProperties.$month_days = month_days;
app.config.globalProperties.$mobile = screen.availWidth < 1024 ? true : false;
app.config.globalProperties.$famous = famous;
